<template>
	<div>
		<div class="main">
			<div class="top">
				<div class="left">
					<div class="left-wrap">
						<div class="icon">
							<img src="../../../assets/images/personalCenter/personal-audition-authority-icon.png" alt="">
						</div>
						<div class="time">{{myInfo.audition_end_time}}{{$t('expire')}}</div>
					</div>
					<div class="desc">{{$t('listeningcard')}}</div>
				</div>
				<div class="right">
					<div class="number">NO.{{myInfo.audition_id}}</div>
					<div class="number-renewals">{{ $t('timesrenewal') }}{{myInfo.activation_count}}{{ $t('frequency') }}</div>
				</div>
			</div>
			<div class="bottom">
				<div class="left">{{$t('Listeningprivilege')}}</div>
				<div class="right" @click="activateAudition">{{$t('srenewal')}}</div>
			</div>
		</div>
		<!-- 绑定成功弹窗 -->
		<PopupCommon ref="popupAuthority" customClassName="textarea-modal" title=" " :width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('srenewal')}}</div>
			<div :slot="'content'" class="pop-content">
				<div class="select-time">
					<div class="title">{{$t('Renewaltime')}}</div>
					<div class="time">1个月</div>
					<div class="number">{{$t('numberofrenewals')}}{{myInfo.activation_count}}{{$t('applyfor')}}</div>
				</div>
				<div class="desc">
					{{$t('Renewaltitle')}}
				</div>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<!-- <div class="cancel" @click="closePop">关闭</div> -->
				<div class="confirm" @click="activateAudition">{{ $t('confirm') }}</div>
			</div>
		</PopupCommon>
	</div>
	
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import {activateAudition, myInfo} from "@/api/Mine"
	import { setMyInfo, getMyInfo, } from "@/utils/common/user";
	export default {
		props: {},
		computed: {},
		components: {
			PopupCommon
		},
		data(){
			return {
				myInfo: {}
			}
		},
		created() {
			this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
		},
		methods: {
			setMyInfo,
			// 打开权限续期弹窗
			AuditionAuthority(){
				// this.$refs.popupAuthority.open()
			},
			// 续期
			activateAudition(){
				let params = {};
				activateAudition(params).then((res) => {
				  console.log(res);
				  if (res.status == 1) {
				    this.$message({
				      type: "success",
				      message: this.$t("renewalsucceeded"),
				    });
					myInfo().then((res) => {
					  this.setMyInfo(res.data)
					});
					this.$refs.popupAuthority.close()
				  } else {
				    this.$message({
				      type: "success",
				      message: this.$t("renewalfaild"),
				    });
				  }
				});
			},
			// cancelBindBank() {
			// 	this.$refs.popupBindBank.close()
			// },
			// confirmBindBank() {
			// 	this.$refs.popupSubmit.open()
			// },
			closePop(){
				this.$refs.popupAuthority.close()
			}
		}
	}
</script>

<style lang="less" scoped>
	.main{
		color: #fff;
		width: 100%;
		height: 200px;
		margin-right: 24px;
		margin-bottom: 24px;
		background-image: url(../../../assets/images/personalCenter/personal-audition-authority-bg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		// margin-left: 28px;
		.top{
			display: flex;
			justify-content: space-between;
			padding: 0 24px;
			align-items: center;
			.left{
				display: flex;
				.left-wrap{
					display: flex;
					flex-direction: column;
					.icon{
						img {
							width: 115px;
							height: 30px;
						}
					}
					.time{
						font-size: 14px;
						color: #FFFFFF;
					}
				}
				.desc{
					display: flex;
					align-items: flex-start;
					margin-top: 8px;
					margin-left: 10px;
					font-size: 14px;
					color: #FFFFFF;
				}
			}
			.right{
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				.number{
					font-size: 24px;
					color: #FFFFFF;
				}
				.number-renewals{
					font-size: 14px;
					color: #FFFFFF;
				}
			}
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			padding: 0 24px;
			.left {
				font-size: 14px;
				color: #FFFFFF;
			}
			.right {
				width: 122px;
				height: 36px;
				background: #FB4060;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 600;
				color: #FFFFFF;
				cursor: pointer;
			}
		}
	}
	// 权限续期弹窗
	.pop-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
		.select-time{
			display: flex;
			height: 84px;
			align-items: center;
			border-bottom: 1px solid @popupCommonBorderColor;
			.title{
				font-size: 14px;
				color: @demoitemsongMusicianColor;
				margin-right: 16px;
			}
			.time{
				width: 74px;
				height: 36px;
				background: rgba(51,112,255,0.05);
				border-radius: 4px;
				border: 1px solid #3370FF;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #3370FF;
				margin-right: 8px;
			}
			.number{
				font-size: 12px;
				color: @recommendMusicianListTitleColor;
			}
		}
		.desc{
			display: flex;
			padding-top: 24px;
			font-size: 12px;
			color: @recommendMusicianListTitleColor;;
		}
		
	}
	
	.pop-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;
	
		.cancel {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor: pointer;
		}
	
		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>